import React from 'react';

import { Button } from "./";

const Message = ({message, deleteMessage}) => (
    <tr>
        <td>{message.email}</td>
        <td>{message.first_name}</td>
        <td>{message.body}</td>
        <td>
            <Button width="40px" height="40px" className="btn btn-danger" onClick={() => deleteMessage(message.id)}><i className="far fa-trash-alt" /></Button>
        </td>
    </tr>
)

export default Message;
