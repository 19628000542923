import React from 'react';
import styles_me from './Index.module.scss';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image';
import lg_meueditor from '../../assets/images/meueditor.png';

import { Label } from "../../components";
import RichTextEditor from 'react-rte';
import Select from 'react-select';
import { Button, Input } from "../../components";
import api from "../../services/api";

import 'bootstrap/dist/css/bootstrap.css';

class blogNew extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      images: [],
      publicated: '',
      manchete: '',
      description: '',
      font: '',
      body: RichTextEditor.createEmptyValue(),
      lang: "",
      languages: [
          { value: 'pt', label: 'Português' },
          { value: 'en', label: 'English' },   
      ],
      order: '',
    }
  }

  onChange = (body) => {
    this.setState({body});
    if (this.props.onChange) {
      // Send the changes up to the parent component as an HTML string.
      // This is here to demonstrate using `.toString()` but in a real app it
      // would be better to avoid generating a string on each change.
      this.props.onChange(
        body.toString('html')
      );
    }
  };

  async addURLArray() {
    const imagesURI = [];

    await Promise.all(
      this.state.images.map(async (file) => {
        const formData = new FormData();
        formData.append('image', file);

        const response = await api.post('/movies/upload', formData);
        imagesURI.push(response.data[1]);
      })
    );

    return imagesURI;
  }

  slug = (string) => {
    return string.toString().toLowerCase()
      .replace(/[àÀáÁâÂãäÄÅåª]+/g, 'a')       // Special Characters #1
      .replace(/[èÈéÉêÊëË]+/g, 'e')       	// Special Characters #2
      .replace(/[ìÌíÍîÎïÏ]+/g, 'i')       	// Special Characters #3
      .replace(/[òÒóÓôÔõÕöÖº]+/g, 'o')       	// Special Characters #4
      .replace(/[ùÙúÚûÛüÜ]+/g, 'u')       	// Special Characters #5
      .replace(/[ýÝÿŸ]+/g, 'y')       		// Special Characters #6
      .replace(/[ñÑ]+/g, 'n')       			// Special Characters #7
      .replace(/[çÇ]+/g, 'c')       			// Special Characters #8
      .replace(/[ß]+/g, 'ss')       			// Special Characters #9
      .replace(/[Ææ]+/g, 'ae')       			// Special Characters #10
      .replace(/[Øøœ]+/g, 'oe')       		// Special Characters #11
      .replace(/[%]+/g, 'pct')       			// Special Characters #12
      .replace(/\s+/g, '-')           		// Replace spaces with -
      .replace(/[^\w\-]+/g, '')       		// Remove all non-word chars
      .replace(/\-\-+/g, '-')         		// Replace multiple - with single -
      .replace(/^-+/, '')             		// Trim - from start of text
      .replace(/-+$/, '');            		// Trim - from end of text
  }

  createPost = async () => {
    try {
        let imagesURI = await this.addURLArray();

        let data = {
          'link': imagesURI,
          'slug' : await this.slug(this.state.manchete),
          'publicated': this.state.publicated,
          'manchete': this.state.manchete,
          'description': this.state.description,
          'body': this.state.body.toString('html'),
          'font': this.state.font,
          'lang': this.state.lang,
          'order': this.state.order
        }

        await api.post("/pages/post_store", data);

        this.props.history.push("/dashboard/blog");
    } catch (err) {
        this.setState({
          error:
            "Nós encontramos um erro enquanto editavamos seu post."
        });
    }
  }

  setLanguage(e) {
    if (e)
      this.setState({ lang: e.value })
  }

  handleInput(e) {
    this.setState({
      [e.target.name] : e.target.value
    })
  }

  handleFileChange(e) {
    this.setState({ images: [...e.target.files] })
  }

  render() {

    return (
        <Container fluid style={{paddingLeft: 80}}>
            <div className={styles_me.bar_top}>
                <Image src={lg_meueditor} className={styles_me.logo}/> <h1>/Criar post</h1>
            </div>
            <Row>
                <Col md={12} style={{paddingTop:70}}>
                    <div>{this.state.error}</div>
                    <div style={{backgroundColor: '#ddd', padding: 30, borderRadius: 3}}>
                        <Row className="align-items-center">
                            <Col md={6}>
                                <Input className="form-control" type="file" accept="image/x-png,image/gif,image/jpeg" name="file" onChange={(e) => this.handleFileChange(e)} />
                            </Col>
                            <Col md={6}>
                            <input className="form-control" type="text" name="manchete" onChange={(e) => this.handleInput(e)} value={this.state.manchete} placeholder="Título" />
                            </Col>
                            <Col md={6}>
                                <input className="form-control" type="text" name="publicated" onChange={(e) => this.handleInput(e)} value={this.state.publicated} placeholder="Autor" />       
                            </Col>
                            <Col md={6}>
                                <input className="form-control" type="text" name="description" onChange={(e) => this.handleInput(e)} value={this.state.description} placeholder="Subtítulo" />
                            </Col>
                            <Col md={6}>
                                <input className="form-control" type="text" name="font" onChange={(e) => this.handleInput(e)} value={this.state.font} placeholder="Fonte do texto" />
                            </Col>
                            <Col md={6}>
                            <RichTextEditor
                              value={this.state.body}
                              onChange={this.onChange}
                            />
                            </Col>
                            <Col md={4}>
                              <Label>Idioma</Label>
                              <Select
                                  options={this.state.languages}
                                  onChange={(e) => this.setLanguage(e)}       
                              />        
                            </Col>
                            <Col md={2}>
                                <Label>Ordem <i  className="fas fa-question-circle" 
                                      style={{color:'grey'}} 
                                      title="Ordem em que o Post aparecerá na lista, 0 sendo primeiro."
                                  ></i>
                                </Label>
                                <input
                                    value={this.state.order}
                                    // type="number"
                                    name="order"
                                    onChange={(e) => this.handleInput(e)}
                                />
                            </Col>
                        </Row>
                    </div>
                    <Row className="mt-4">
                        <Col md={3} lg={2}>
                            <Button onClick={() => this.createPost()} className="btn btn-success w-100"><i className="far fa-save" /> Criar Post</Button>
                        </Col>
                        <Col md={3} lg={2}>
                            <Button onClick={() => this.props.history.goBack()} className="btn btn-dark w-100"><i className="fas fa-chevron-left" /> Voltar</Button>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </Container>
    );
  }
}

export default blogNew;
