import React, { Component } from 'react';
import styles_me from './Index.module.scss';

import api from "../../services/api";
import { login } from "../../services/auth";

import { Form, Input, Button } from "../../components";

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image';
import lg_meueditor from '../../assets/images/meueditor.png';


class Login extends Component {
  state = {
    email: "",
    password: "",
    error: ""
  };

  handleSignIn = async e => {
    e.preventDefault();
    const { email, password } = this.state;
    if (!email || !password) {
      this.setState({ error: "Preencha os dados para continuar" });
    } else {
      try {
        const response = await api.post("/users/authenticate", { email, password });
        login(response.data.token);
        this.props.history.push("/dashboard");
      } catch (err) {
        this.setState({
          error:
            "Nós encontramos um erro enquanto logamos em sua conta."
        });
      }
    }
  };

  render() {
    return (
      <Container style={{height:'100%'}}>
        <Row className="align-items-center"  style={{height:'100%'}}>
          <Col md={12}>
            <div className={styles_me.card_login}>
              <Image src={lg_meueditor} className={styles_me.logo}/>
              <Form onSubmit={this.handleSignIn}>
                {this.state.error && <p>{this.state.error}</p>}
                <Input type="text" name="email" onChange={e => this.setState({ email: e.target.value })} value={this.state.email} placeholder="Email" />
                <Input type="password" name="password" onChange={e => this.setState({ password: e.target.value })} value={this.state.password} placeholder="Password" />
                <Button className={styles_me.btn}>Login</Button>
              </Form>
            </div>
          </Col>
        </Row>
      </Container>
    );
  }
}

export default Login;
