import React, { Component } from 'react';
import styles_me from './Index.module.scss';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image';
import lg_meueditor from '../../assets/images/meueditor.png';

import { Button } from "../../components";
import Navigation from '../../components/Navigation';
import api from "../../services/api";

import Post from '../../components/Post';

class Blog extends Component {

    constructor(props) {
        super(props);

        this.state = {
            posts: [],
            pagesPerPage: 5,
        };

        this.changePage = this.changePage.bind(this);
        this.deletePost = this.deletePost.bind(this);
    }

    async componentWillMount() {
        try {
            const response = await api.get("/pages/show_all/blog");

            const allPages = response["data"];
            
            const pagesPerPage = this.state.pagesPerPage;
            const countPages = Math.ceil(allPages.length / pagesPerPage);
            const currentPage = 1;

            const posts = allPages.slice(0, pagesPerPage);

            this.setState({
                posts,
                countPages,
                currentPage,
                allPages
            });
        } catch (err) {
            this.setState({
              error:
                "Nós encontramos um erro ao pegar os posts."
            });
        }
    }

    changePage = (number) => {
        number++;

        const { allPages, pagesPerPage } = this.state;
        const posts = allPages.slice(pagesPerPage * (number-1), number * pagesPerPage);

        this.setState({
            posts,
            currentPage: number
        })
    }

    deletePost = async (slug) => {
        try {
            await api.delete("/pages/post_destroy/" + slug);

            const posts = this.state.posts.filter(post => post.slug !== slug)
            this.setState({
                posts
            })
        } catch (err) {
            this.setState({
                error:
                "Nós encontramos um erro enquanto deletavamos seu filme."
            });
        }
    }

    render() {
        return (
            <Container fluid style={{paddingLeft: 80}}>
                <div className={styles_me.bar_top}>
                    <Image src={lg_meueditor} className={styles_me.logo}/> <h1>/Posts</h1>
                </div>
                <Row>
                    <Col md={12} style={{paddingTop:70}}>
                        <Button backgroundColor="green" width="40px" float="right" onClick={() => this.props.history.push("/dashboard/blog/create")}>
                            <i className="fas fa-folder-plus"/>
                        </Button>
                        <table className="table table-hover">
                            <thead>
                                <tr>
                                    <th style={{width:'180px'}}>Imagem</th>
                                    <th>Publicado Por:</th>
                                    <th>Título</th>
                                    <th>Subtítulo</th>
                                    <th>Lang</th>
                                    <th style={{width:'120px'}}>Ações</th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.state.posts.map((post, i) => <Post deletePost={(id) => this.deletePost(id)} key={i} post={post} history={this.props.history}/>)}
                            </tbody>
                        </table>

                        <Navigation changePage={(number) => this.changePage(number)} {...this.state}/>
                    </Col>
                </Row>
            </Container>
        );
    }
}

export default Blog;
