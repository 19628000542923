import React from 'react';
import styles_me from './homeUpdate.module.scss';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image';
import lg_meueditor from '../../assets/images/meueditor.png';

import RichTextEditor from 'react-rte';

import { Button, Input, Label } from "../../components";
import Select from 'react-select';
import api from "../../services/api";

import 'bootstrap/dist/css/bootstrap.css';

class sobreUpdate extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      images: [],
      title_image: '',
      title: RichTextEditor.createEmptyValue(),
      description: RichTextEditor.createEmptyValue(),
      sobre: '',
      destacados: '',
      movies: '',
      successMovies: [],
      successText: RichTextEditor.createEmptyValue(),
      lang: this.props.match.params.lang,
      market_position: RichTextEditor.createEmptyValue(),
      file_market_position:[]
    }
  }

  async componentWillMount() {
    try {
      const response = await api.get("/pages/show/sobre/" + this.state.lang);
      if(response.data.content)
        this.setState({
          title_image: response.data.content.files[0].link_title,
          title: RichTextEditor.createValueFromString(response.data.content.files[0].title, 'html'),
          description: RichTextEditor.createValueFromString(response.data.content.files[0].description, 'html'),
          sobre: response.data.content.files[0].link,
          destacados: response.data.content.files[0].successMovies,
          successMovies: response.data.content.files[0].successMovies,
          successText: RichTextEditor.createValueFromString(response.data.content.files[0].successText, 'html'),
          market_position: RichTextEditor.createValueFromString(response.data.content.files[0].market_position, 'html'),
        });

      let movies = [];
      const moviesApi = await api.get('/movies/index/' + this.state.lang);

      moviesApi['data'].map(movie => {
          return movies.push({
              value: movie.slug,
              label: movie.name,
          });
      });

      this.setState({
          movies
      })
    } catch (err) {
        this.setState({
          error:
            "Nós encontramos um erro enquanto editavamos sua página."
        });
    }
  }

  async addURLArray() {
    const imagesURI = [];

    await Promise.all(
      this.state.images.map(async (file) => {
        const formData = new FormData();
        formData.append('image', file.file[0]);

        const response = await api.post('/movies/upload', formData);
        imagesURI.push(response.data[1]);
      })
    );

    return imagesURI;
  }

  async addPdfURLArray() {
    const pdfURI = [];

    await Promise.all(
      this.state.file_market_position.map(async (file) => {
        const formData = new FormData();
        formData.append('image', file.file[0]);

        const response = await api.post('/movies/upload', formData);
        pdfURI.push(response.data[1]);
      })
    );

    return pdfURI;
  }

  

  updatePage = async () => {
    try {
        let imagesURI = await this.addURLArray();
        let pdfURI = await this.addPdfURLArray();
        
        if(!imagesURI.length) {
          imagesURI = this.state.sobre;
        }
        if(!pdfURI.length) {
          pdfURI = this.state.sobre;
        }

        let data = {
          'content' : {
            'files': [
              {
                'link': imagesURI,
                'link_title': this.state.title_image,
                'title': this.state.title.toString('html'),
                'description': this.state.description.toString('html'),
                'successMovies': this.state.successMovies,
                'successText': this.state.successText.toString('html'),
                'market_position': this.state.market_position.toString('html'),
                'file_market_position': pdfURI
              }
            ]
          }
        }

        await api.put("/pages/update/sobre/" + this.state.lang, data);

        this.props.history.push("/dashboard/pages");
    } catch (err) {
        this.setState({
          error:
            "Nós encontramos um erro enquanto editavamos sua página."
        });
    }
  }

  handleInput(e) {
    this.setState({
      [e.target.name] : e.target.value
    })
  }

  handleFileChange(e) {
    this.setState({
      images: [{ 'name': this.state.titulo_imagem, 'link': '', 'file': [...e.target.files] } ]
    })
  }
  handleFileChange2(e) {
    this.setState({
      file_market_position: [{ 'link': '', 'file': [...e.target.files] } ]
    })
  }
  
  setSuccess(e) {
    if (e)
        this.setState({ successMovies: [...e] })
  }

  onChange1 = (title) => {
    this.setState({title});
    if (this.props.onChange) {
      this.props.onChange(
        title.toString('html')
      );
    }
  };

  onChange2 = (description) => {
    this.setState({description});
    if (this.props.onChange) {
      this.props.onChange(
        description.toString('html')
      );
    }
  };

  onChange3 = (successText) => {
    this.setState({successText});
    if (this.props.onChange) {
      this.props.onChange(
        successText.toString('html')
      );
    }
  };

  onChange4 = (market_position) => {
    this.setState({market_position});
    if (this.props.onChange) {
      this.props.onChange(
        market_position.toString('html')
      );
    }
  };

  render() {

    return (
        <Container fluid style={{paddingLeft: 80}}>
            <div className={styles_me.bar_top}>
                <Image src={lg_meueditor} className={styles_me.logo}/> <h1>/Editar página sobre</h1>
            </div>
            <Row>
                <Col md={12} style={{paddingTop:70}}>
                    <div>{this.state.error}</div>
                    <Label>Selecionar imagem</Label>
                    <div style={{backgroundColor: '#ddd', padding: 30, borderRadius: 3}}>
                        <Row className="align-items-center">
                            <Col md={6} className="mb-3">
                                <Input className="form-control" type="file" accept="image/x-png,image/gif,image/jpeg" name="file" onChange={(e) => this.handleFileChange(e)} />
                            </Col>
                            <Col md={6} className="mb-3">
                                <input className="form-control" type="text" name="title_image" onChange={(e) => this.handleInput(e)} value={this.state.title_image} placeholder="Título imagem" />
                            </Col>
                            <Col md={6}>
                              <RichTextEditor
                                    value={this.state.title}
                                    onChange={this.onChange1}
                                    placeholder="Título"
                                    className="w-100"
                                />
                            </Col>
                            <Col md={6}>
                              <RichTextEditor
                                    value={this.state.description}
                                    onChange={this.onChange2}
                                    placeholder="Descrição"
                                    className="w-100"
                                />
                                <br/>
                                <RichTextEditor
                                    value={this.state.market_position}
                                    onChange={this.onChange4}
                                    placeholder="Posição no mercado"
                                    className="w-100"
                                />
                                <br/>
                                <Input className="form-control" type="file" name="file" onChange={(e) => this.handleFileChange2(e)} />
                            </Col>
                            

                            <hr
                              style={{
                                  color: "black",
                                  backgroundColor: "black",
                                  height: 1,
                                  width: "100%",
                                  marginTop: "15px",
                                  padding: "0px"
                              }}
                            />

                            <Col md={12}>
                              <Label>Filmes Destacados</Label>
                            </Col>

                            <Col md={8}>
                              <RichTextEditor
                                      value={this.state.successText}
                                      onChange={this.onChange3}
                                      placeholder="Texto de Sucesso"
                                      className="w-100"
                                  />
                            </Col>

                            <Col md={4}>
                              <Select
                                      placeholder="Filmes Destacados"
                                      value={this.state.successMovies}
                                      options={this.state.movies}
                                      onChange={(e) => this.setSuccess(e)}
                                      isMulti
                                      />
                            </Col>
                        </Row>
                    </div>
                    <Row>
                        <Col md={3} lg={2}>
                            <Button onClick={() => this.updatePage()} className="btn btn-success w-100"><i className="far fa-save"/> Salvar Página</Button>
                        </Col>
                        <Col md={3} lg={2}>
                            <Button onClick={() => this.props.history.goBack()} className="btn btn-dark w-100"><i className="fas fa-chevron-left"/> Voltar</Button>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </Container>
    );
  }
}

export default sobreUpdate;
