import React from 'react';
import styles_me from './politicaUpdate.module.scss';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image';
import lg_meueditor from '../../assets/images/meueditor.png';

import RichTextEditor from 'react-rte';

import { Button, Input, Label } from "../../components";
import api from "../../services/api";

import 'bootstrap/dist/css/bootstrap.css';

class politicaUpdate extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      value: RichTextEditor.createEmptyValue(),
      lang: this.props.match.params.lang
    }
  }

  async componentWillMount() {
    try {
      const response = await api.get("/pages/show/privacy/" + this.state.lang);
      if(response.data.content)
        this.setState({
          value: RichTextEditor.createValueFromString(response.data.content.files[0], 'html')
        });
    } catch (err) {
        this.setState({
          error:
            "Nós encontramos um erro enquanto buscavamos a página privacidade."
        });
    }
  }

  onChange = (value) => {
    this.setState({value});
    if (this.props.onChange) {
      // Send the changes up to the parent component as an HTML string.
      // This is here to demonstrate using `.toString()` but in a real app it
      // would be better to avoid generating a string on each change.
      this.props.onChange(
        value.toString('html')
      );
    }
  };

  updatePage = async () => {
    try {
        let data = {
          'content' : {
            'files': [this.state.value.toString('html')]
          }
        }

        await api.put("/pages/update/privacy/" + this.state.lang, data);

        this.props.history.push("/dashboard/pages");
    } catch (err) {
        this.setState({
          error:
            "Nós encontramos um erro enquanto editavamos sua página."
        });
    }
  }

  handleInput(e) {
    this.setState({
      [e.target.name] : e.target.value
    })
  }

  render() {
    let {value} = this.state;
    return (
        <Container fluid style={{paddingLeft: 80}}>
            <div className={styles_me.bar_top}>
                <Image src={lg_meueditor} className={styles_me.logo}/> <h1>/Editar página privacidade</h1>
            </div>
            <Row>
                <Col md={12} style={{paddingTop:70}}>
                    <div>{this.state.error}</div>
                    <Label>Política de Privacidade</Label>
                    <div style={{backgroundColor: '#ddd', padding: 30, borderRadius: 3}}>
                        <Row className="align-items-center">
                            <Col md={12} className="mb-3">
                              <RichTextEditor
                                value={value}
                                onChange={this.onChange}
                              />
                            </Col>
                        </Row>
                    </div>
                    <Row>
                        <Col md={3} lg={2}>
                            <Button onClick={() => this.updatePage()} className="btn btn-success w-100"><i className="far fa-save"/> Salvar Página</Button>
                        </Col>
                        <Col md={3} lg={2}>
                            <Button onClick={() => this.props.history.goBack()} className="btn btn-dark w-100"><i className="fas fa-chevron-left"/> Voltar</Button>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </Container>
    );
  }
}

export default politicaUpdate;
