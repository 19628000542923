import React from 'react';
import styles_me from './homeUpdate.module.scss';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image';
import lg_meueditor from '../../assets/images/meueditor.png';

import { Button, Input, Label } from "../../components";
import api, { uploadPath } from "../../services/api";

import 'bootstrap/dist/css/bootstrap.css';

class homeUpdate extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      numChildren: 1,
      images: [],
      imagesSaved: [],
      lang: this.props.match.params.lang
    }
  }

  async componentWillMount() {
    try {
      const response = await api.get("/pages/show/home/" + this.state.lang);
      if(response.data.content)
        this.setState({imagesSaved: response.data.content.files});
    } catch (err) {
        this.setState({
          error:
            "Nós encontramos um erro enquanto editavamos sua página."
        });
    }
  }

  async addURLArray() {
    const imagesURI = [];

    if(this.state.imagesSaved){
      await Promise.all(
        this.state.imagesSaved.map(async (file) => {
          imagesURI.push({
            'name' : file.name,
            'link' : file.link,
            'link_movie' : file.link_movie,
            'order_movie' : file.order_movie,
            
          })
        })
      );
    }

    await Promise.all(
      
      this.state.images.map(async (file) => {
        console.log(this.state.images)
        const formData = new FormData();
        formData.append('image', file.file[0]);

        const response = await api.post('/movies/upload', formData);
        imagesURI.push({
          'name' : file.name,
          'link' : response.data[1],
          'link_movie' : file.link_movie,
          'order_movie' : file.order_movie
        });
      })
    );

    return imagesURI;
  }

  updatePage = async () => {
    try {
        let imagesURI = await this.addURLArray();
        let data = {
          'content': {
            'files': imagesURI
          }
        };

        await api.put("/pages/update/home/" + this.state.lang, data);

        this.props.history.push("/dashboard/pages");
    } catch (err) {
        this.setState({
          error:
            "Nós encontramos um erro enquanto editavamos sua página."
        });
    }
  };

  deleteImage = (e) => {
    const imagesSaved = this.state.imagesSaved.filter( image => image.link !== e.currentTarget.dataset.name);

    this.setState({
      imagesSaved
    });
  };

  addFile = () => {
    this.setState({
      numChildren: this.state.numChildren+1
    })
  };

  handleFileChangeNew(e, i) {
    i--;
    this.setState({
      images: [...this.state.images, { 'name': this.refs['child'+i].value, 'link_movie': this.refs['child_link'+i].value, 'order_movie': this.refs['child_order'+i].value,  'file': [...e.target.files] } ]
    });
  }

  handleFileChange = async (e, image) => {
    const pictures = this.state.imagesSaved;
    let i = null;
    
    pictures.forEach((picture, index) => {
      if(picture.link === image.link && picture.name === image.name && picture.link_movie === image.link_movie) {
        i = index;
        image = picture;
      }
    })
    
    if(i !== null) {
      const formData = new FormData();
      formData.append('image', e.target.files[0]);
      
      const response = await api.post('/movies/upload', formData);
     
      image["link"] = response.data[1];
      pictures[i] = image;

      this.setState({
        imagesSaved: [...pictures]
      });

      console.log(this.state.imagesSaved);
    }
  }

  handleInputName(e, i) {
    i--;

    const images = this.state.images;
    if(images[i]) {
        images[i]["name"] = e.target.value;
    }
  }

  handleInputLinkMovie(e, i) {
    i--;

    const images = this.state.images;

    if(images[i]) {
        images[i]["link_movie"] = e.target.value;
        
    }
  }

  handleInputOrderMovie(e, i) {
    i--;

    const images = this.state.images;

    if(images[i]) {
      images[i]["order_movie"] = e.target.value;
        
    }
  }

  handleInputNameSaved(e, object) {
    const pictures = this.state.imagesSaved;
    let i = null;

    pictures.forEach((picture, index) => {
      if(picture.link === object.link && picture.name === object.name && picture.link_movie === object.link_movie) {
        i = index;
        object = picture;
      }
    })

    console.log(i);

    if(i !== null) {
      object["name"] = this.refs[object.link+'dad_name'+object.name].value;
      object["link_movie"] = this.refs[object.link+'dad_link_movie'+object.link_movie].value;
      object["order_movie"] = this.refs[object.link+'dad_order'+object.order_movie].value;
      pictures[i] = object;

      this.setState({
        imagesSaved: [...pictures]
      });
    }
  }

  
  render() {

    const children = [];

    if (this.state.imagesSaved) {
      this.state.imagesSaved.map( image => {
        children.push(
          <div key={image.link} className="row align-items-center mb-4 pb-4 border-bottom border-dark">
              <div className="col-md-1">
                  <input className="form-control" type="number" onChange={(e) => this.handleInputNameSaved(e,image)} ref={image.link+"dad_order"+image.order_movie} value={image.order_movie} placeholder="Ordem" />
              </div>
              <div className="col-md-1">
                  <img className="form-control img-fluid" src={uploadPath + image.link} alt="test" />
              </div>
              <div className="col-md-2">
                  <input className="form-control" type="file" accept="image/x-png,image/gif,image/jpeg" name="fileSaved" onChange={(e) => this.handleFileChange(e, image)} />
              </div>
              <div className="col-md-3">
                  <input className="form-control" type="text" onChange={(e) => this.handleInputNameSaved(e,image)} ref={image.link+"dad_link_movie"+image.link_movie} value={image.link_movie} placeholder="Link imagem" />
              </div>
              <div className="col-md-4">
                  <input className="form-control" type="text" onChange={(e) => this.handleInputNameSaved(e,image)} ref={image.link+"dad_name"+image.name} value={image.name} placeholder="Título imagem" />
              </div>
              <div className="col-md-1">
                  <button className="btn btn-danger" data-name={image.link} onClick={e => this.deleteImage(e)}><i className="fas fa-trash-alt"/></button>
              </div>
          </div>
        );
      });
    }

    for (var i = 0; i < this.state.numChildren; i++) {
      children.push(
        <div key={i} className="row align-items-center">
            <div className="col-md-1">
                <input className="form-control" type="number" onChange={(e) => this.handleInputOrderMovie(e,i)} ref={"child_order"+i} placeholder="Ordem" />
            </div>
            <div className="col-md-3 text-center">
                <Input className="form-control" type="file" accept="image/x-png,image/gif,image/jpeg" name="file" onChange={(e) => this.handleFileChangeNew(e, i)} />
            </div>
            <div className="col-md-2">
                  <Input className="form-control" type="text" onChange={(e) => this.handleInputLinkMovie(e,i)} ref={"child_link"+i} placeholder="Link imagem" />
              </div>
            <div className="col-md-6 text-center">
                <Input className="form-control" type="text" onChange={(e) => this.handleInputName(e, i)} ref={"child"+i} placeholder="Título imagem" />
            </div>
        </div>
      );
    }

    return (
        <Container fluid style={{paddingLeft: 80}}>
            <div className={styles_me.bar_top}>
                <Image src={lg_meueditor} className={styles_me.logo}/> <h1>/Editar página home</h1>
            </div>
            <Row>
                <Col md={12} style={{paddingTop:70}}>
                    <div>{this.state.error}</div>

                    <Label>Carrousel home</Label>

                    <hr
                      style={{
                          color: "black",
                          backgroundColor: "black",
                          height: 1,
                          width: "100%",
                          marginTop: "15px",
                          padding: "0px"
                      }}
                    />

                    <Label>Selecionar imagens</Label>

                    <div style={{backgroundColor: '#ddd', padding: 30, borderRadius: 3}}>
                        {children}
                    </div>

                    <div className="text-center">
                        <Button onClick={() => this.addFile()} width="40px" height="40px" backgroundColor="green"><i className="fas fa-folder-plus" /></Button>
                    </div>

                    <Row>
                        <Col md={3} lg={2}>
                            <Button onClick={() => this.updatePage()} className="btn btn-success w-100"><i className="far fa-save"/> Salvar Página</Button>
                        </Col>
                        <Col md={3} lg={2}>
                            <Button onClick={() => this.props.history.goBack()} className="btn btn-dark w-100"><i className="fas fa-chevron-left"/> Voltar</Button>
                        </Col>
                    </Row>


                </Col>
            </Row>
        </Container>
    );
  }
}

export default homeUpdate;
