import React from 'react';
import { BrowserRouter as Router, Route, Switch, Redirect } from "react-router-dom";

import { isAuthenticated } from "./services/auth";
import Login from './pages/Login';
import SignUp from './pages/SignUp';
import Panel from './pages/Panel';

import Pages from './pages/Pages';
import Blog from './pages/Blog';
import blogNew from './pages/Blog/blogNew';
import blogUpdate from './pages/Blog/blogUpdate';
import homeUpdate from './pages/Pages/homeUpdate';
import sobreUpdate from './pages/Pages/sobreUpdate';
import politicaUpdate from './pages/Pages/politicaUpdate';
import filmesUpdate from './pages/Pages/filmesUpdate';
import Messages from './pages/Messages';

import Users from './pages/Users';

import Movies from './pages/Movies';
import movieNew from './pages/Movies/movieNew';
import movieUpdate from './pages/Movies/movieUpdate';

import SideMenu from './components/sideMenu';

const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={props =>
      isAuthenticated() ? (
        <>
        <SideMenu/>
        <Component {...props} />
        </>
      ) : (
        <Redirect to={{ pathname: "/", state: { from: props.location } }} />
      )
    }
  />
);

const Routes = (props) => {
    return(
        <Router>
            <Switch>
                <Route path="/" exact component={Login} />
                <Route path="/signup" component={SignUp} />
                <PrivateRoute path="/dashboard" exact component={Panel} />

                <PrivateRoute path="/dashboard/messages" exact component={Messages} />

                <PrivateRoute path="/dashboard/pages" exact component={Pages} />
                <PrivateRoute path="/dashboard/pages/update/home/:lang" component={homeUpdate} />
                <PrivateRoute path="/dashboard/pages/update/filmes/:lang" component={filmesUpdate} />
                <PrivateRoute path="/dashboard/pages/update/sobre/:lang" component={sobreUpdate} />
                <PrivateRoute path="/dashboard/pages/update/privacy/:lang" exact component={politicaUpdate} />

                <PrivateRoute path="/dashboard/blog" exact component={Blog} />
                <PrivateRoute path="/dashboard/blog/create" component={blogNew} />
                <PrivateRoute path="/dashboard/blog/update/:slug" component={blogUpdate} />

                <PrivateRoute path="/dashboard/movies" exact component={Movies} />
                <PrivateRoute path="/dashboard/movies/create" component={movieNew} />
                <PrivateRoute path="/dashboard/movies/update/:id" component={movieUpdate} />

                <PrivateRoute path="/dashboard/users" exact component={Users} />
                <PrivateRoute path="/dashboard/users/create" component={movieNew} />
                <PrivateRoute path="/dashboard/users/update/:id" component={movieUpdate} />

                <Route path="*" component={() => <h1>Page not found</h1>} />
            </Switch>
        </Router>
    )
};

export default Routes;