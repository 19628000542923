import React from 'react';
import styles_me from './Index.module.scss';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image';
import lg_meueditor from '../../assets/images/meueditor.png';

// import { Container, Title } from "../../components";

// Be sure to include styles at some point, probably during your bootstraping
import '@trendmicro/react-sidenav/dist/react-sidenav.css';

const Panel = () => (

    <Container fluid style={{paddingLeft: 80}}>
        <div className={styles_me.bar_top}>
            <Image src={lg_meueditor} className={styles_me.logo}/> <h1>/Dashboard</h1>
        </div>
        <Row>
            <Col md={12} style={{paddingTop:70}}>

            </Col>
        </Row>
    </Container>
)

export default Panel;
