import React from 'react';

import SideNav, { NavItem, NavIcon, NavText } from '@trendmicro/react-sidenav';
import { withRouter } from "react-router-dom";
import { logout } from "../services/auth";

class SideMenu extends React.Component {

    logout = () => {
        logout();
        this.props.history.push("/");
    }

    render() {
        return(
            <SideNav
                className="sidemenu"
                onSelect={(selected) => {
                    this.setState({
                        pathname: selected
                    });
                    const to = '/dashboard/' + selected;
                    if (this.props.location.pathname !== to && selected !== "sair") {
                        this.props.history.push(to);
                    }
                }}
            >
                <SideNav.Toggle />
                <SideNav.Nav defaultSelected="home">
                    <NavItem eventKey={"pages"}>
                        <NavIcon>
                            <i className="fa fa-fw fa-file" style={{ fontSize: '1.75em' }} />
                        </NavIcon>
                        <NavText>
                            Paginas
                        </NavText>
                    </NavItem>
                    <NavItem eventKey="movies">
                        <NavIcon>
                            <i className="fa fa-fw fa-film" style={{ fontSize: '1.75em' }} />
                        </NavIcon>
                        <NavText>
                            Filmes
                        </NavText>
                    </NavItem>
                    <NavItem eventKey="blog">
                        <NavIcon>
                            <i className="fab fa-fw fa-blogger" style={{ fontSize: '1.75em' }} ></i>
                        </NavIcon>
                        <NavText>
                            Blog
                        </NavText>
                    </NavItem>
                    <NavItem eventKey="messages">
                        <NavIcon>
                            <i className="fas fa-inbox" style={{ fontSize: '1.75em' }}></i>
                        </NavIcon>
                        <NavText>
                            Contato
                        </NavText>
                    </NavItem>
                    <NavItem onClick={() => this.logout()} eventKey="sair">
                        <NavIcon>
                            <i className="fas fa-fw fa-sign-out-alt" style={{ fontSize: '1.75em' }} ></i>
                        </NavIcon>
                        <NavText>
                            Sair
                        </NavText>
                    </NavItem>
                </SideNav.Nav>
            </SideNav>
        )
    }
}
export default withRouter(SideMenu);
