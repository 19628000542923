import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import styles_me from './Index.module.scss';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image';
import lg_meueditor from '../../assets/images/meueditor.png';

import { Form, Input, Button } from "../../components";

import api from "../../services/api";

class SignUp extends Component {
    state = {
      name: "",
      email: "",
      password: "",
      password_confirmation: "",
      error: ""
    };

    handleSignUp = async e => {
      e.preventDefault();
      const { name, email, password, password_confirmation } = this.state;
      if (!name || !email || !password || !password_confirmation) {
        this.setState({ error: "Preencha todos os dados do registro." });
      } else if (password !== password_confirmation) {
        this.setState({ error: "Senhas não coincidem." });
      }else {
        try {
          await api.post("/users/store", { name, email, password, password_confirmation });
          this.props.history.push("/");
        } catch (err) {
          this.setState({ error: "Nós encontramos um erro enquanto registramos sua conta." });
        }
      }
    };

    render() {
        return(
          <Container style={{height:'100%'}}>
              <Row className="align-items-center" style={{height:'100%'}}>
                  <Col md={12}>
                      <div className={styles_me.card_login}>
                          <Image src={lg_meueditor} className={styles_me.logo}/>
                          <Form onSubmit={this.handleSignUp}>
                              {this.state.error && <p>{this.state.error}</p>}
                              <Input type="text" name="name" onChange={e => this.setState({ name: e.target.value })} placeholder="Nome" />
                              <Input type="text" name="email" onChange={e => this.setState({ email: e.target.value })} placeholder="Email" />
                              <Input type="password" name="password" onChange={e => this.setState({ password: e.target.value })} placeholder="Senha" />
                              <Input type="password" name="confirm_password" onChange={e => this.setState({ password_confirmation: e.target.value })} placeholder="Confirmar Senha" />
                              <Button className={styles_me.btn} default>Registrar</Button>
                              <Link to="/" className={styles_me.links}><i className="fas fa-arrow-left"></i> Voltar para o Login</Link>
                          </Form>
                      </div>
                  </Col>
              </Row>
          </Container>
        );
    }
}

export default SignUp;
