import React, { Component } from 'react';
import styles_me from './Index.module.scss';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image';
import lg_meueditor from '../../assets/images/meueditor.png';

import { Button } from "../../components";
import Navigation from '../../components/Navigation';
import api from "../../services/api";

import User from '../../components/User';

class Users extends Component {

    constructor(props) {
        super(props);

        this.state = {
            users: [],
            pagesPerPage: 5,
        };

        this.changePage = this.changePage.bind(this);
        this.deleteUser = this.deleteUser.bind(this);
    }

    async componentWillMount() {
        try {
            const allPages = await api.get("/users/index");

            const pagesPerPage = this.state.pagesPerPage;
            let countPages = Math.ceil(allPages.length / pagesPerPage);
            
            const currentPage = 1;
            let users;

            if(!countPages > 0) {
                countPages = 1;
                users = allPages;
            }else{
                users = allPages.slice(0, pagesPerPage);
            };

            this.setState({
                users,
                countPages,
                currentPage,
                allPages
            });
        } catch (err) {
            this.setState({
              error:
                "Nós encontramos um erro ao pegar os posts."
            });
        }
    }

    changePage = (number) => {
        number++;

        const { allPages, pagesPerPage } = this.state;
        const users = allPages.slice(pagesPerPage * (number-1), number * pagesPerPage);

        this.setState({
            users,
            currentPage: number
        })
    }

    deleteUser = async (id) => {
        try {
            await api.delete("/users/destroy/" + id);

            const allPages = this.state.allPages.filter(user => user.id !== id)
            this.setState({
                allPages
            })

            this.setState({countPages: Math.ceil(allPages.length / this.state.pagesPerPage)});

            if(this.state.users.length > 1){
                this.changePage(this.state.currentPage - 1);
            }else{
                this.changePage(this.state.currentPage - 2);
            }
        } catch (err) {
            this.setState({
                error:
                "Nós encontramos um erro enquanto deletavamos o usuário."
            });
        }
    }

    render() {
        return (
            <Container fluid style={{paddingLeft: 80}}>
                <div className={styles_me.bar_top}>
                    <Image src={lg_meueditor} className={styles_me.logo}/> <h1>/Usuários</h1>
                </div>
                <Row>
                    <Col md={12} style={{paddingTop:70}}>
                        <Button backgroundColor="green" width="40px" float="right" onClick={() => this.props.history.push("/dashboard/blog/create")}>
                            <i className="fas fa-folder-plus"/>
                        </Button>
                        <table className="table table-hover">
                            <thead>
                                <tr>
                                    <th>#</th>
                                    <th>Nome</th>
                                    <th>E-mail</th>
                                    <th style={{width:'120px'}}>Ações</th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.state.users.map((user) => <User deleteUser={(id) => this.deleteUser(id)} key={user.id} user={user} history={this.props.history}/>)}
                            </tbody>
                        </table>

                        <Navigation changePage={(number) => this.changePage(number)} {...this.state}/>
                    </Col>
                </Row>
            </Container>
        );
    }
}

export default Users;