import styled from 'styled-components';

/*
    Aqui joga o css deste component
    Propriedade red não existe, por isto utiliza o blue. 
    Entretando a propriedade default existe, então aplica a propriedade
*/

const defaultProps = object => {
    switch(object){
        case "color":
            return "#ffffff";
        case "backgroundColor":
            return "#5995ef";
        case "width":
            return "300px";
        case "marginTop":
            return "10px";
        case "marginBottom":
            return "10px";
        case "float":
            return "none";
        case "height":
            return "35px";
        default:
            return "#ffffff";
    }
}

///////////////////* Default Components *///////////////////

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100vh;
  width: 100%
`;

export const Title = styled.h1`
  font-family: 'Raleway', sans-serif;
  font-weight: 600;
  color: #4d4d4d;
  font-size: 2.2em;
  margin-top: 10px;
  margin-bottom: 10px;
`;

///////////////////* Navigation *////////////////////

export const NavigationSpan = styled.span`
  font-family: 'Raleway', sans-serif;
  font-size: 1em;
  cursor: pointer;
  padding: 5px;
  border: 1px solid blue;
  color: ${props => props.selected ? "white" : "blue"};
  background-color: ${props => props.selected ? "blue" : "white"};
`;

export const NavigationButton = styled.button`
  width: 30px;
  height: 29px;
  cursor: pointer;
  border: 1px solid blue;
  color: blue;
  position: relative;
  margin-right: 5px;
  margin-left: 5px;
  background-color: white;
`;

///////////////////* Form Components *////////////////////

export const Form = styled.form`
  width: 300px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Input = styled.input`
  width: 290px;
  height: 35px;
  border: 1px solid #ccc;
  background-color: #fff;
  margin-top: 5px;
  margin-bottom: 5px;
  padding-left: 5px;
`;

export const InputMask = styled.input`
  width: 290px;
  height: 35px;
  border: 1px solid #ccc;
  background-color: #fff;
  margin-top: 5px;
  margin-bottom: 5px;
  padding-left: 5px;
`;

export const Button = styled.button`
  color: ${props => props.color ? props.color : defaultProps('color')}
  background-color: ${props => props.backgroundColor ? props.backgroundColor : defaultProps('backgroundColor')}
  width: ${props => props.width ? props.width : defaultProps('width')};
  height: ${props => props.height ? props.height : defaultProps('height')};
  border-radius: 3px;
  cursor: pointer;
  margin-top: ${props => props.marginTop ? props.marginTop : defaultProps('marginTop')};
  margin-bottom: ${props => props.marginBottom ? props.marginBottom : defaultProps('marginBottom')};
  float: ${props => props.float ? props.float : defaultProps('float')};
`;

export const Label = styled.label`
  font-family: 'Raleway', sans-serif;
  font-size: 20px;
  color: "black";
  margin: 15px
  margin-left: 0px;
`;