import React from 'react';
import { uploadPath } from '../services/api';
import { Button } from ".";

const User = ({user, history, deleteUser}) => (
    <tr>
        <td>{user.id}</td>
        <td>{user.name}</td>
        <td>{user.email}</td>
        <td>
            <Button width="40px" height="40px" className="btn btn-success" onClick={() => history.push("/dashboard/user/update/"+user.id)}><i className="far fa-edit" /></Button>
            <Button width="40px" height="40px" className="btn btn-danger ml-3" onClick={() => deleteUser(user.id)}><i className="far fa-trash-alt" /></Button>
        </td>
    </tr>
);

export default User;
