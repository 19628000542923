import { createGlobalStyle } from "styled-components";

import "@fortawesome/fontawesome-free/css/all.min.css";

const GlobalStyle = createGlobalStyle`
* {
    box-sizing: border-box;
    padding: 0;
    margin: 0;
    outline: 0;
  }
body, html {
background-color: #f1f1f1 !important;
font-family: 'Helvetica Neue', 'Helvetica', Arial, sans-serif !import;
text-rendering: optimizeLegibility !important;
-webkit-font-smoothing: antialiased !important;
height: 100%;
width: 100%;
}
.main-site {height: 100%;}
`;

export default GlobalStyle;
