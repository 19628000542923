import React from 'react';

import { Button } from "./";

const Page = ({page, history}) => (
    <tr>
        <td>{page.name}</td>
        <td>{page.slug}</td>
        <td style={{width:'20px'}}>
            <Button className="btn btn-success" style={{width:'40px', height:'40px', margin:'0'}} onClick={() => history.push("/dashboard/pages/update/"+page.slug+"/"+page.lang)}><i className="far fa-edit" /></Button>
        </td>
    </tr>
)

export default Page;
