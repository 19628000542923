import React from 'react';
import styles_me from './Index.module.scss';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image';
import lg_meueditor from '../../assets/images/meueditor.png';
import InputMask from 'react-input-mask';
import RichTextEditor from 'react-rte';

import { Button, Form, Input, Label } from "../../components";
import Select from 'react-select';
import api from "../../services/api";

class movieNew extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            name: '',
            production: '',
            direction: '',
            actors: '',
            genre: '',
            year: '',
            launch_date: '',
            summary: RichTextEditor.createEmptyValue(),
            link_video: '',
            facebook: '',
            twitter: '',
            instagram: '',
            imagemCard: [],
            link_imagem_card: "",
            imagemNormal: [],
            link_imagem_normal: "",
            imagemLogo: [],
            link_imagem_logo: "",
            imagemRelacionada: [],
            link_imagem_relacionada: "",
            imagemPdf: [],
            movies: [],
            relations: [],
            lang: "",
            direction_img: 0,
            languages: [
                { value: 'pt', label: 'Português' },
                { value: 'en', label: 'English' },
            ],
            link_assista_aqui: "",
            link_ingressos: "",
            link_pdf: ""
        };
    }

    async componentWillMount() {
        let movies = [];
        const moviesApi = await api.get('/movies/index_all');

        moviesApi['data'].map(movie => {
            return movies.push({
                value: movie.id,
                label: movie.name,
            });
        });

        this.setState({
            movies,
            classificationOpt: [
                {
                    value: "L",
                    label: "L",
                }
                ,
                {
                    value: "10",
                    label: "10",
                }
                ,
                {
                    value: "12",
                    label: "12",
                },
                {
                    value: "14",
                    label: "14",
                }
                ,
                {
                    value: "16",
                    label: "16",
                }
                ,
                {
                    value: "18",
                    label: "18",
                }

            ]
        })
    }

    async addURLArray() {
        const imagesURI = [];
        await Promise.all(this.state.imagemCard.map(async (file) => {
            const formData = new FormData();
            formData.append('image', file);

            const response = await api.post('/movies/upload', formData);
            // imagesURI.push([0, response.data[1]]);
            await this.setState({ link_imagem_card: response.data[1].split("/")[1] })
        }));

        await Promise.all(this.state.imagemNormal.map(async (file) => {
            const formData = new FormData();
            formData.append('image', file);

            const response = await api.post('/movies/upload', formData);
            // imagesURI.push([1, response.data[1]]);
            await this.setState({ link_imagem_normal: response.data[1].split("/")[1] })
        }));

        await Promise.all(this.state.imagemLogo.map(async (file) => {
            const formData = new FormData();
            formData.append('image', file);

            const response = await api.post('/movies/upload', formData);
            // imagesURI.push([2, response.data[1]]);
            await this.setState({ link_imagem_logo: response.data[1].split("/")[1] })
        }));

        await Promise.all(this.state.imagemRelacionada.map(async (file) => {
            const formData = new FormData();
            formData.append('image', file);

            const response = await api.post('/movies/upload', formData);
            await this.setState({ link_imagem_relacionada: response.data[1].split("/")[1] })
        }));

        // if(this.state.imagemPdf) {
        await Promise.all(this.state.imagemPdf.map(async (file) => {
            const formData = new FormData();
            formData.append('image', file);

            const response = await api.post('/movies/upload', formData);
            this.setState({ link_pdf: response.data[1].split("/")[1] })
        }));
        // }

        return imagesURI;
    }

    slug = (string) => {
        return string.toString().toLowerCase()
            .replace(/[àÀáÁâÂãäÄÅåª]+/g, 'a')       // Special Characters #1
            .replace(/[èÈéÉêÊëË]+/g, 'e')       	// Special Characters #2
            .replace(/[ìÌíÍîÎïÏ]+/g, 'i')       	// Special Characters #3
            .replace(/[òÒóÓôÔõÕöÖº]+/g, 'o')       	// Special Characters #4
            .replace(/[ùÙúÚûÛüÜ]+/g, 'u')       	// Special Characters #5
            .replace(/[ýÝÿŸ]+/g, 'y')       		// Special Characters #6
            .replace(/[ñÑ]+/g, 'n')       			// Special Characters #7
            .replace(/[çÇ]+/g, 'c')       			// Special Characters #8
            .replace(/[ß]+/g, 'ss')       			// Special Characters #9
            .replace(/[Ææ]+/g, 'ae')       			// Special Characters #10
            .replace(/[Øøœ]+/g, 'oe')       		// Special Characters #11
            .replace(/[%]+/g, 'pct')       			// Special Characters #12
            .replace(/\s+/g, '-')           		// Replace spaces with -
            .replace(/[^\w\-]+/g, '')       		// Remove all non-word chars
            .replace(/\-\-+/g, '-')         		// Replace multiple - with single -
            .replace(/^-+/, '')             		// Trim - from start of text
            .replace(/-+$/, '');            		// Trim - from end of text
    }

    async createMovie() {
        // console.log('oi');
        // try {
        const { classification, name, direction, production, actors, genre, year, launch_date, summary, link_video, facebook, twitter, instagram, relations, lang, direction_img, link_assista_aqui, link_ingressos, link_pdf } = this.state;

        let images = await this.addURLArray();

        let data = {
            classification,
            name,
            direction,
            production,
            slug: await this.slug(name),
            actors,
            genre,
            year,
            launch_date,
            summary: summary.toString('html'),
            link_video,
            link_imagem_card: this.state.link_imagem_card,
            link_imagem_normal: this.state.link_imagem_normal,
            link_imagem_logo: this.state.link_imagem_logo,
            link_imagem_relacionada: this.state.link_imagem_relacionada,
            social: [
                { 'name': 'facebook', 'url': facebook },
                { 'name': 'twitter', 'url': twitter },
                { 'name': 'instagram', 'url': instagram }],
            //images,
            relations,
            lang,
            direction_img,
            link_assista_aqui,
            link_ingressos,
            link_pdf: this.state.link_pdf
        };
        console.log(JSON.stringify(data));
        await api.post("/movies/store", data).then(response => {
            //console.log(response)
            this.props.history.push("/dashboard/movies");
        })
            .catch(err => {
                console.log(err)
            })

        // } catch (err) {
        //     this.setState({
        //         error:
        //             "Nós encontramos um erro ao criar seu filme."
        //     });
        // }
    }

    handleInputChange(e) {
        this.setState({
            [e.target.name]: e.target.value
        });
    }

    handleFileChangeCard(e) {
        this.setState({ imagemCard: [...e.target.files] })
    }

    handleFileChangeNormal(e) {
        this.setState({ imagemNormal: [...e.target.files] })
    }

    handleFileChangeLogo(e) {
        this.setState({ imagemLogo: [...e.target.files] })
    }

    handleFileChangeRelacionada(e) {
        this.setState({ imagemRelacionada: [...e.target.files] })
    }

    handleFileChangePDF(e) {
        this.setState({ imagemPdf: [...e.target.files] })
    }

    setRelation(e) {
        if (e)
            this.setState({ relations: [...e] })
    }
    setclassification(e) {

        var a = [];
        if (e) {
          a[0] = e
    
          this.setState({ classification: [...a] })
        }
      }
    setLanguage(e) {
        if (e)
            this.setState({ lang: e.value })
    }


    setDirectionImg(e) {
        if (e)
            this.setState({ direction_img: e.value })
    }


    onChange = (summary) => {
        this.setState({ summary });
        if (this.props.onChange) {
            // Send the changes up to the parent component as an HTML string.
            // This is here to demonstrate using `.toString()` but in a real app it
            // would be better to avoid generating a string on each change.
            this.props.onChange(
                summary.toString('html')
            );
        }
    };

    render() {
        return (
            <Container fluid style={{ paddingLeft: 80 }}>
                <div className={styles_me.bar_top}>
                    <Image src={lg_meueditor} className={styles_me.logo} /> <h1>/Criar filme</h1>
                </div>
                <Row>
                    <Col md={12} style={{ paddingTop: 70 }}>
                        <Form className="w-100 d-block">
                            <Row>
                                <Col md={6} lg={4} className="mb-3">
                                    <Input placeholder="Nome" name="name" className="w-100" value={this.state.name} onChange={(e) => this.handleInputChange(e)} />
                                </Col>
                                <Col md={6} lg={4} className="mb-3">
                                    <Input placeholder="Direção" name="direction" className="w-100" value={this.state.direction} onChange={(e) => this.handleInputChange(e)} />
                                </Col>
                                <Col md={6} lg={4} className="mb-3">
                                    <Input placeholder="Produção" name="production" className="w-100" value={this.state.production} onChange={(e) => this.handleInputChange(e)} />
                                </Col>
                                <Col md={6} lg={4} className="mb-3">
                                    <Input placeholder="Atores" name="actors" className="w-100" value={this.state.actors} onChange={(e) => this.handleInputChange(e)} />
                                </Col>
                                <Col md={6} lg={4} className="mb-3">
                                    <Input placeholder="Gênero" name="genre" className="w-100" value={this.state.genre} onChange={(e) => this.handleInputChange(e)} />
                                </Col>
                                <Col md={3} lg={2} className="mb-3">
                                    <Input type="number" placeholder="Ano" name="year" className="w-100" value={this.state.year} onChange={(e) => this.handleInputChange(e)} />
                                </Col>
                                <Col md={3} lg={2} className="mb-3">
                                    <InputMask mask="99/99/9999" placeholder="Data Lançamento" name="launch_date" className="w-100" value={this.state.launch_date} onChange={(e) => this.handleInputChange(e)} />
                                </Col>
                                <Col md={12} lg={12} className="mb-3">
                                    <RichTextEditor
                                        rows="4"
                                        value={this.state.summary}
                                        onChange={this.onChange}
                                        placeholder="Resumo"
                                        className="w-100"
                                    />
                                </Col>
                                <Col md={6} lg={3} className="mb-3">
                                    <Input placeholder="Código do Video" name="link_video" className="w-100" value={this.state.link_video} onChange={(e) => this.handleInputChange(e)} />
                                </Col>
                                <Col md={6} lg={3} className="mb-3">
                                    <Input placeholder="Facebook" name="facebook" className="w-100" value={this.state.facebook} onChange={(e) => this.handleInputChange(e)} />
                                </Col>
                                <Col md={6} lg={3} className="mb-3">
                                    <Input placeholder="Spotify" name="twitter" className="w-100" value={this.state.twitter} onChange={(e) => this.handleInputChange(e)} />
                                </Col>
                                <Col md={6} lg={3} className="mb-3">
                                    <Input placeholder="Instagram" name="instagram" className="w-100" value={this.state.instagram} onChange={(e) => this.handleInputChange(e)} />
                                </Col>

                                <Col md={12}>
                                    <hr
                                        style={{
                                            color: "black",
                                            backgroundColor: "black",
                                            height: 1,
                                            width: "100%",
                                            marginTop: "15px",
                                            padding: "0px"
                                        }}
                                    />

                                    <Col style={{ padding: 0 }} md={12} lg={12}>
                                        <Label>Imagem da listagem <small><i class="fas fa-question-circle" style={{ color: 'grey' }} title="Essa imagem ficará na tela de filmes, vertical: 685x845"></i></small></Label>
                                        <Input name="file" type="file" className="w-100" accept="image/x-png,image/gif,image/jpeg" onChange={(e) => this.handleFileChangeCard(e)} />
                                    </Col>
                                    <Col style={{ padding: 0 }} md={3} lg={3}>
                                        <Select
                                            options={[
                                                { value: '0', label: 'Vertical' },
                                                { value: '1', label: 'Horizontal' },
                                            ]}
                                            onChange={(e) => this.setDirectionImg(e)}
                                        />
                                    </Col>
                                    <Label>Imagem destaque interno <small><i class="fas fa-question-circle" style={{ color: 'grey' }} title="Essa imagem ficará no topo da descrição do filme, dimensão: 1920x660 "></i></small></Label>

                                    <Input name="file" type="file" className="w-100" accept="image/x-png,image/gif,image/jpeg" onChange={(e) => this.handleFileChangeNormal(e)} />

                                    <Label>Imagem poster <small><i class="fas fa-question-circle" style={{ color: 'grey' }} title="Imagem do poster que ficará na descrição do filme, dimensão: 600x881 "></i></small></Label>

                                    <Input name="file" type="file" className="w-100" accept="image/x-png,image/gif,image/jpeg" onChange={(e) => this.handleFileChangeLogo(e)} />

                                    <Label>Imagem Relacionada <small><i class="fas fa-question-circle" style={{ color: 'grey' }} title="Imagem que será mostrada na tela de outro filme quando este estiver relacionado, dimensão: 680x400  "></i></small></Label>

                                    <Input name="file" type="file" className="w-100" accept="image/x-png,image/gif,image/jpeg" onChange={(e) => this.handleFileChangeRelacionada(e)} />

                                    <Label>PDF</Label>

                                    <Input name="file" type="file" className="w-100" accept=".pdf" onChange={(e) => this.handleFileChangePDF(e)} />

                                    <hr
                                        style={{
                                            color: "black",
                                            backgroundColor: "black",
                                            height: 1,
                                            width: "100%",
                                            marginTop: "15px",
                                            padding: "0px"
                                        }}
                                    />
                                </Col>
                                <Col md={2}>
                                    <Label>Classificação</Label>

                                    <Select

                                        options={this.state.classificationOpt}
                                        onChange={(e) => this.setclassification(e)}
                                    />

                                    <hr
                                        style={{
                                            color: "black",
                                            backgroundColor: "black",
                                            height: 1,
                                            width: "100%",
                                            marginTop: "30px",
                                            padding: "0px"
                                        }}
                                    />
                                </Col>

                                <Col md={12}>
                                    <Label>Filmes Relacionados</Label>

                                    <Select
                                        options={this.state.movies}
                                        onChange={(e) => this.setRelation(e)}
                                        isMulti
                                    />

                                    <hr
                                        style={{
                                            color: "black",
                                            backgroundColor: "black",
                                            height: 1,
                                            width: "100%",
                                            marginTop: "30px",
                                            padding: "0px"
                                        }}
                                    />
                                </Col>
                                <Col md={4}>
                                    <Label>Idioma</Label>
                                    <Select
                                        options={this.state.languages}
                                        onChange={(e) => this.setLanguage(e)}
                                    />
                                </Col>
                                <Col md={4}>
                                    <Label>Link "Assista aqui"  <i className="fas fa-question-circle" style={{ color: 'grey' }} title="Caso queira adicionar mais de um link, utilize a seguinte sintaxe: Nome da Plataforma :: Link do filme, Nome da segunda plataforma :: Link do segundo filme, etc. Exemplo.: NOW :: https://www.nowonline.com.br/, Apple TV :: https://www.apple.com/br/tv/"></i>
                                    </Label>
                                    <Input placeholder='URL' name="link_assista_aqui" value={this.state.link_assista_aqui} onChange={(e) => this.handleInputChange(e)} />
                                </Col>
                                <Col md={4}>
                                    <Label>Link "Ingressos"</Label>
                                    <Input placeholder='URL' name="link_ingressos" value={this.state.link_ingressos} onChange={(e) => this.handleInputChange(e)} />
                                </Col>

                            </Row>
                        </Form>
                        <Row>
                            <Col md={3} lg={2}>
                                <Button onClick={() => this.createMovie()} className="btn btn-success w-100"><i className="far fa-save" /> Criar Filme</Button>
                            </Col>
                            <Col md={3} lg={2}>
                                <Button onClick={() => this.props.history.goBack()} className="btn btn-dark w-100"><i className="fas fa-chevron-left" /> Voltar</Button>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Container>
        );
    }
}

export default movieNew;
