import React from 'react';
import { uploadPath } from '../services/api';
import { Button } from ".";

const Post = ({post, history, deletePost}) => (
    
    <tr>
        <td><img className="img-fluid" src={uploadPath + post.link}/></td>
        <td>{post.publicated}</td>
        <td>{post.manchete}</td>
        <td>{post.description}</td>
        <td>{post.lang}</td>
        <td>
            <Button width="40px" height="40px" className="btn btn-success" onClick={() => history.push("/dashboard/blog/update/"+post.slug)}><i className="far fa-edit" /></Button>
            <Button width="40px" height="40px" className="btn btn-danger ml-3" onClick={() => deletePost(post.slug)}><i className="far fa-trash-alt" /></Button>
        </td>
    </tr>
);

export default Post;
