import React from 'react';
import GlobalStyle from './styles'

import Routes from './Routes';

const App = () => (
    <>
        <Routes />
        <GlobalStyle/>
    </>
);

export default App;
