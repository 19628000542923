import React, { Component } from 'react';
import styles_me from './Index.module.scss';
import './pagination.css';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image';
import lg_meueditor from '../../assets/images/meueditor.png';


import Navigation from '../../components/Navigation';
import api from "../../services/api";

import Message from '../../components/Message';

class Pages extends Component {

    constructor(props) {
        super(props);

        this.state = {
            messages: [],
            pagesPerPage: 5,
        };

        this.changePage = this.changePage.bind(this);
        this.deleteMessage = this.deleteMessage.bind(this);
    }

    async componentWillMount() {
        try {
            const response = await api.get("/contacts/index");

            const allPages = response["data"];
            const pagesPerPage = this.state.pagesPerPage;
            const countPages = Math.ceil(allPages.length / pagesPerPage);
            const currentPage = 1;

            const messages = allPages.slice(0, pagesPerPage);

            this.setState({
                messages,
                countPages,
                currentPage,
                allPages
            });
        } catch (err) {
            this.setState({
              error:
                "Nós encontramos um erro ao pegar as páginas."
            });
        }
    }

    changePage = (number) => {
        number++;

        const { allPages, pagesPerPage } = this.state;
        const messages = allPages.slice(pagesPerPage * (number-1), number * pagesPerPage);

        this.setState({
            messages,
            currentPage: number
        })
    }

    deleteMessage = async (id) => {
        try {
            await api.delete("/contacts/destroy/" + id);

            const allPages = this.state.allPages.filter(message => message.id !== id)

            this.setState({
                allPages
            });

            this.setState({countPages: Math.ceil(allPages.length / this.state.pagesPerPage)});

            if(this.state.messages.length > 1){
                this.changePage(this.state.currentPage - 1);
            }else{
                this.changePage(this.state.currentPage - 2);
            }
        } catch (err) {
            this.setState({
                error:
                "Nós encontramos um erro enquanto deletavamos sua mensagem."
            });
        }
    };

    render() {
        return (
            <Container fluid style={{paddingLeft: 80}}>
                <div className={styles_me.bar_top}>
                    <Image src={lg_meueditor} className={styles_me.logo}/> <h1>/Contato</h1>
                </div>
                <Row>
                    <Col md={12} style={{paddingTop:70}}>
                        <table className="table">
                            <thead>
                                <tr>
                                    <th>Email</th>
                                    <th>Nome</th>
                                    <th>Mensagem</th>
                                    <th style={{width:'120px'}}>Ações</th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.state.messages.map((message) => <Message key={message.id} deleteMessage={(id) => this.deleteMessage(id)} message={message}/>)}
                            </tbody>
                        </table>

                        <div className="pagination">
                            <Navigation changePage={(number) => this.changePage(number)} {...this.state}/>
                        </div>
                    </Col>
                </Row>
            </Container>
        );
    }
}

export default Pages;
