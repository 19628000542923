import React from 'react';

import { Button } from "./";

const Movie = ({movie, history, deleteMovie}) => (
    <tr>
        <td>{movie.name}</td>
        <td>{movie.production}</td>
        <td>{movie.direction}</td>
        <td>{movie.lang}</td>
        <td>
            <Button width="40px" height="40px" className="btn btn-success" onClick={() => history.push("/dashboard/movies/update/"+movie.id+"/"+movie.lang)}><i className="far fa-edit" /></Button>
            <Button width="40px" height="40px" className="btn btn-danger ml-3" onClick={() => deleteMovie(movie.id)}><i className="far fa-trash-alt" /></Button>
        </td>
    </tr>
)

export default Movie;
