import React from 'react';
import styles_me from './filmesUpdate.module.scss';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image';
import lg_meueditor from '../../assets/images/meueditor.png';

import { Button, Input, Label } from "../../components";
import api, { uploadPath } from "../../services/api";
import Select from 'react-select';
import 'bootstrap/dist/css/bootstrap.css';

class filmesUpdate extends React.Component {

  constructor(props) {
	super(props);

	this.state = {
	//   numChildren: 1,
	//   images: [],
	//   imagesSaved: [],
		error:"",
		movies: [],
        selectedMovies: {
			movie1: {
                value: "",
                label: ""
            },
			movie2: {
                value: "",
                label: ""
            },
			movie3: {
                value: "",
                label: ""
            },
			movie4: {
                value: "",
                label: ""
            },
			movie5: {
                value: "",
                label: ""
            },
		},
		lang: this.props.match.params.lang
	}
  }

  async componentWillMount() {
   
	try {
	  let movies = [];
	  const moviesApi = await api.get('/movies/index/' + this.state.lang);
        
	  moviesApi['data'].map(movie => {
		  return movies.push({
			  value: movie.id,
			  label: movie.name,
		  });
      });


	  this.setState({
		  movies
	  });
	} catch (err) {
		this.setState({
		  error:
			"Nós encontramos um erro enquanto editavamos sua página."
		});
	}
	try {
	  const response = await api.get("/pages/show/filmes/" + this.state.lang);
	  if(response.data.content)
		this.setState({selectedMovies: response.data.content});
	} catch (err) {
		this.setState({
		  error:
			"Nós encontramos um erro enquanto editavamos sua página."
		});
	}
  }

  
  updatePage = async () => {
	try {
        let data = {
            'content' : this.state.selectedMovies
        }
        await api.put("/pages/update/filmes/" + this.state.lang, data)
        .then((response) => {
            this.props.history.push("/dashboard/pages");
        })
        .catch((error) => {
            console.log(error);
        })
        
	} catch (err) {
		this.setState({
		  error:
			"Nós encontramos um erro enquanto editavamos sua página."
		});
	}
  };


  handleInputMovie(e, name) {
    const selectedMovies = this.state.selectedMovies;
    
	Object.keys(selectedMovies).map((index) => {
        if(index == name){
            selectedMovies[index].value = e.value;
            selectedMovies[index].label = e.label;
        }
	});
	this.setState(selectedMovies);
  }


  render() {
	return (
		<Container fluid style={{paddingLeft: 80}}>
			<div className={styles_me.bar_top}>
				<Image src={lg_meueditor} className={styles_me.logo}/> <h1>/Editar página filmes</h1>
			</div>
			<Row>
				<Col md={12} style={{paddingTop:70}}>
					<div>{this.state.error}</div>
					<Label>Filmes em destaque</Label>

					<div style={{backgroundColor: '#ddd', padding: 30, borderRadius: 3 }}>
						<div className="row align-items-center">
							<div className="col-md-12 text-center">
								{/* <Input className="form-control" type="text" onChange={(e) => this.handleInputName(e)} placeholder="Primeiro Filme" /> */}
								<Select
									options={this.state.movies}
                                    onChange={(e) => this.handleInputMovie(e, 'movie1')}
                                    value={this.state.selectedMovies.movie1}
									name="movie1"
									placeholder="Primeiro Filme"
                                    
								/>
							</div>  
						</div>
						<br/>
						<div className="row align-items-center">
							<div className="col-md-12 text-center">
								{/* <Input className="form-control" type="text" onChange={(e) => this.handleInputName(e)} placeholder="Segundo Filme" /> */}
								<Select
									options={this.state.movies}
                                    onChange={(e) => this.handleInputMovie(e, 'movie2')}
                                    value={this.state.selectedMovies.movie2}
									name="movie2"
									placeholder="Segundo Filme"
                                    
								/>
							</div>
						</div>
						<br/>
						<div className="row align-items-center">
							<div className="col-md-12 text-center">
								{/* <Input className="form-control" type="text" onChange={(e) => this.handleInputName(e)} placeholder="Terceiro Filme" /> */}
								<Select
									options={this.state.movies}
                                    onChange={(e) => this.handleInputMovie(e, 'movie3')}
                                    value={this.state.selectedMovies.movie3}
									name="movie3"
									placeholder="Terceiro Filme"
                                    
								/>
							</div>
						</div>
						<br/>
						<div className="row align-items-center">
							<div className="col-md-12 text-center">
								{/* <Input className="form-control" type="text" onChange={(e) => this.handleInputName(e)} placeholder="Quarto Filme" /> */}
								<Select
									options={this.state.movies}
                                    onChange={(e) => this.handleInputMovie(e, 'movie4')}
                                    value={this.state.selectedMovies.movie4}
									name="movie4"
									placeholder="Quarto Filme"
                                    
								/>
							</div>
						</div>
						<br/>
						<div className="row align-items-center">
							<div className="col-md-12 text-center">
								{/* <Input className="form-control" type="text" onChange={(e) => this.handleInputName(e)} placeholder="Quinto Filme" /> */}
								<Select
									options={this.state.movies}
                                    onChange={(e) => this.handleInputMovie(e, 'movie5')}
                                    value={this.state.selectedMovies.movie5}
									name="movie5"
									placeholder="Quinto Filme"
                                    
								/>
							</div>
						</div>
					</div>
					<Row>
						<Col md={3} lg={2}>
							<Button onClick={() => this.updatePage()} className="btn btn-success w-100"><i className="far fa-save"/> Salvar Página</Button>
						</Col>
						<Col md={3} lg={2}>
							<Button onClick={() => this.props.history.goBack()} className="btn btn-dark w-100"><i className="fas fa-chevron-left"/> Voltar</Button>
						</Col>
					</Row>
				</Col>
			</Row>
		</Container>
	);
  }
}

export default filmesUpdate;
