import React, { Component } from 'react';
import { NavigationSpan, NavigationButton } from "./";

export default class Navigation extends Component {
    render() {
        return (
            <>
                {this.props.currentPage > 1 && this.props.countPages > 1 &&
                    <NavigationButton onClick={() => this.props.changePage(this.props.currentPage - 2)}><i className="fa fa-fw fa-caret-left" style={{ fontSize: '1.2em' }} /></NavigationButton>
                }
            
                {this.props.countPages > 1 &&
                    Array.apply(0, Array(this.props.countPages)).map((x, i) => {
                        return (i + 1 === this.props.currentPage) ? 
                            <NavigationSpan key={i} selected onClick={() => this.props.changePage(i)}>{i+1}</NavigationSpan> 
                            : 
                            <NavigationSpan key={i} onClick={() => this.props.changePage(i)}>{i+1}</NavigationSpan>
                    })
                }
            
                {this.props.currentPage < this.props.countPages && this.props.countPages > 1 &&
                    <NavigationButton onClick={() => this.props.changePage(this.props.currentPage)}><i className="fa fa-fw fa-caret-right" style={{ fontSize: '1.2em' }} /></NavigationButton>
                }
            </>
        )
    }
}