import React, { Component } from 'react';
import styles_me from './Index.module.scss';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image';
import lg_meueditor from '../../assets/images/meueditor.png';

import { Button } from "../../components";
import Navigation from '../../components/Navigation';
import Movie from '../../components/Movie';

import api from "../../services/api";

class Movies extends Component {

    constructor(props) {
        super(props);

        this.state = {
            movies: [],
            pagesPerMovie: 8,
        };

        this.changePage = this.changePage.bind(this);
    }

    async componentWillMount() {
        try {
            const response = await api.get("/movies/index_all");

            const allMovies = response["data"];
            const pagesPerMovie = this.state.pagesPerMovie;
            const countPages = Math.ceil(allMovies.length / pagesPerMovie);
            const currentPage = 1;

            const movies = allMovies.slice(0, pagesPerMovie);

            this.setState({
                movies,
                countPages,
                currentPage,
                allMovies
            });
        } catch (err) {
            this.setState({
              error:
                "Nós encontramos um erro ao pegar os filmes."
            });
        }
    }

    deleteMovie = async (id) => {
        try {
            await api.delete("/movies/destroy/" + id);

            const allMovies = this.state.allMovies.filter((movie) => movie.id !== id);
            this.setState({
                allMovies
            })

            this.setState({countPages: Math.ceil(allMovies.length / this.state.pagesPerMovie)});

            if(this.state.movies.length > 1){
                this.changePage(this.state.currentPage - 1);
            }else{
                this.changePage(this.state.currentPage - 2);
            }
        } catch (err) {
            this.setState({
                error:
                "Nós encontramos um erro enquanto deletavamos seu filme."
            });
        }
    }

    changePage = (number) => {
        number++;

        const { allMovies, pagesPerMovie } = this.state;
        const movies = allMovies.slice(pagesPerMovie * (number-1), number * pagesPerMovie);

        this.setState({
            movies,
            currentPage: number
        })
    }

    render() {
        return (
            <>
            <Container fluid style={{paddingLeft: 80}}>
                <div className={styles_me.bar_top}>
                    <Image src={lg_meueditor} className={styles_me.logo}/> <h1>/Filmes</h1>
                </div>
                <Row>
                    <Col md={12} style={{paddingTop:70}}>
                        <Button backgroundColor="green" width="40px" float="right" onClick={() => this.props.history.push("/dashboard/movies/create")}>
                            <i className="fas fa-folder-plus"/>
                        </Button>
                        <table className="table" style={{clear: 'both'}}>
                            <thead>
                                <tr>
                                    <th>Nome</th>
                                    <th>Direção</th>
                                    <th>Produção</th>
                                    <th>Lang</th>
                                    <th style={{width: '120px'}}>Ações</th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.state.movies.map((movie) => <Movie key={movie.id} movie={movie} history={this.props.history} deleteMovie={(movie_id) => this.deleteMovie(movie_id)}/>)}
                            </tbody>
                        </table>

                        <div className="pagination">
                            <Navigation changePage={(number) => this.changePage(number)} {...this.state}/>
                        </div>
                    </Col>
                </Row>
            </Container>
            </>
        );
    }
}

export default Movies;
